<template>
  <div class="warp">
    <div class="login">
      <div class="login-title">
        {{ title }}
        <div>
          技术服务平台
        </div>
      </div>
      <div class="login-con">
        <!-- 用户登录 -->
        <div class="login-p" v-if="isLogin">
          <div class="login-con-title">用户登录</div>
          <!-- <a-form
            style="margin-top: 40px;"
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
            @submit="handleSubmit"
          > -->
          <a-form
            style="margin-top: 40px;"
            id="components-form-demo-normal-login"
            class="login-form"
          >
            <a-form-item>
              <a-input placeholder="请输入用户名" v-model="username" >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0,0,0,.25)"
                />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                type="password"
                placeholder="请输入密码"
                v-model="password"
              >
                <a-icon
                  slot="prefix"
                  type="lock"
                  style="color: rgba(0,0,0,.25)"
                />
              </a-input>
            </a-form-item>
            <a-form-item style="text-align: right">
              <p class="login-form-forgot" @click="toPassword()">
                忘记密码
              </p>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                class="login-form-button"
                @click="handleSubmit()"
              >
                登 录
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- 重置密码 -->
        <div class="forgetPassword-p" v-else>
          <div class="login-con-title">重置密码</div>
          <div class="forgetPassword-div">
            <input
              class="forgetPassword-input"
              type="text"
              v-model="phonenumber"
              :maxLength = "11"
              placeholder="手机号"
            />
          </div>
          <div class="forgetPassword-div">
            <input
              class="forgetPassword-input"
              type="text"
              v-model="verCode"
              placeholder="验证码"
            />
            <button
              v-if="!isCode"
              class="forgetPassword-button"
              @click="sendSms()"
            >
              获取验证码
            </button>
            <button v-if="isCode" class="forgetPassword-button disabled">
              {{ time }}秒后重新发送
            </button>
          </div>
          <div class="forgetPassword-div">
            <input
              class="forgetPassword-input"
              autocomplete="new-password"
              type="password"
              v-model="resetPassword"
              placeholder="新密码：8-16位字符，包含字母、数字和特殊字符"
            />
          </div>
          <a-form-item style="margin-top: 40px;">
            <a-button
              type="primary"
              html-type="submit"
              class="login-form-button"
              @click="resetSubmit()"
            >
              确 定
            </a-button>
            <div style="text-align: right">
              已有账号？<span
                style="cursor: pointer;color:#03BB7A"
                @click="toLogin()"
                >马上登录</span
              >
            </div>
          </a-form-item>
          <div></div>
        </div>
      </div>
      <!-- <button @click="handleSubmit()">333</button> -->
    </div>
    <div class="bottom">
      <div>
        备案号: 津ICP备
      </div>
      <a href="https://beian.miit.gov.cn/" target="banck" class="bottom-a">
        <u>19001159</u>
      </a>
      <div>
        号
      </div>
      <!--<div class="bottom-name">天津易讯文传科技有限公司版权所有</div>-->
      <div class="bottom-name">中国水利水电科学研究院</div>

    </div>
  </div>
</template>

<script type="text/javascript">
import userLogin from "@/api/login/index";
import { getMenuList } from "@/api/permission/menu/index";
import { showConfig } from "@/api/config/index";
import { changePassword,sendMessage } from "@/api/permission/user/index";
export default {
  name: "login",
  data() {
    return {
      timer: "", //验证码定时器
      isCode: false, //验证码点击切换展示
      time: "59", //验证码时间
      isLogin: true, //登录或者忘记密码
      username: "",
      password: "",
      resetPassword: "",
      phonenumber: "",
      verCode: "",
      admin: false,
      title: "",
      hostname: ''
    };
  },
  created() {
    const host = window.location.host;
    this.hostname = window.location.hostname;
    if(host.indexOf("xhdt") !== -1){
      this.title = "宣和东台供水站自动监控与信息管理系统";
    }else if (host.indexOf("shanxi") !== -1) {
      this.title = "山西农村供水信息化";
    } else if (host.indexOf("liaoning") !== -1) {
      this.title = "辽宁农村供水信息化";
    } else if (host.indexOf("spt") !== -1) {
      this.title = "沙坡头区村镇饮水安全信息管理系统";
    } else {
      this.title = "农村供水信息化";
    }
  },
  methods: {
    handleSubmit() {
      userLogin({
        username: this.username,
        password: this.password,
        hostname: this.hostname
      }).then(res => {
        if (res.code == 0) {
          let permission = res.data.permissions;
          if (permission.indexOf("admin") !== -1) {
            this.admin = true;

            localStorage.setItem("permission_edit", true);
          }

          if (res.data.roles[0]) {
            localStorage.setItem(
              "data_permissions",
              res.data.roles[0].permission
            );

            //存储报告编辑权限
            let permission_edit = false;
            let data_permissions = JSON.parse(res.data.roles[0].permission);
            if (data_permissions.length == 3) {
              let focus = data_permissions[2];
              if (focus.checked) {
                let columns = focus.children;
                for (let i in columns) {
                  let result = columns[i];
                  if (result.column == "report_edit" && result.checked)
                    permission_edit = true;
                }
              }
            }
            console.log("permission_edit = " + permission_edit);
            localStorage.setItem("permission_edit", permission_edit);
          }

          localStorage.setItem("admin", this.admin ? 1 : 0);
          localStorage.setItem(
            "province",
            JSON.stringify({
              id: res.data.dept.province,
              name: res.data.dept.province_name
            })
          );
          localStorage.setItem(
            "city",
            JSON.stringify({
              id: res.data.dept.city,
              name: res.data.dept.city_name
            })
          );
          localStorage.setItem(
            "area",
            JSON.stringify({
              id: res.data.dept.area,
              name: res.data.dept.area_name
            })
          );

          // by lanfe 增加所属行政区划信息存储
          if (
            res.data.dept.area !== undefined &&
            res.data.dept.area !== null &&
            res.data.dept.area.length > 0
          ) {
            // 县用户
            localStorage.setItem(
              "areaValue",
              JSON.stringify({
                depId: res.data.dept_id,
                areaId: res.data.dept.area,
                areaName: res.data.dept.area_name
              })
            );
            localStorage.setItem("userLevel", 3);
          } else if (
            res.data.dept.city !== undefined &&
            res.data.dept.city !== null &&
            res.data.dept.city.length > 0
          ) {
            //市用户
            localStorage.setItem(
              "areaValue",
              JSON.stringify({
                depId: res.data.dept_id,
                areaId: res.data.dept.city,
                areaName: res.data.dept.city_name
              })
            );
            localStorage.setItem("userLevel", 2);
          } else if (
            res.data.dept.province !== undefined &&
            res.data.dept.province !== null &&
            res.data.dept.province.length > 0
          ) {
            //省用户
            localStorage.setItem(
              "areaValue",
              JSON.stringify({
                depId: res.data.dept_id,
                areaId: res.data.dept.province,
                areaName: res.data.dept.province_name
              })
            );
            localStorage.setItem("userLevel", 1);
          } else {
            //省用户
            localStorage.setItem(
              "areaValue",
              JSON.stringify({
                depId: res.data.dept_id,
                areaId: 0,
                areaName: "系统"
              })
            );
            localStorage.setItem("userLevel", 1);
          }
          console.log(localStorage.getItem("areaValue"));

          this.getMenus();
          this.getSysConfig();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetSubmit(){
      var formData = new FormData();
      formData.append("code", this.verCode);
      formData.append("password", this.resetPassword);
      formData.append("phone", this.phonenumber);
      changePassword(formData).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success('密码修改成功！');
          this.time = "59";
          this.isCode = false;
          clearInterval(this.timer); //清除定时器
          this.isLogin = true;
          this.username= "";
          this.password= "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getMenus() {
      getMenuList().then(res => {
        if (res.code == 200) {
          localStorage.setItem("menus", JSON.stringify(res.data));
        }
        // if (this.admin) {
        this.$router.push({ path: "/navigation1" });
        // } else {
        //   this.$router.push({ path: "/navigation" });
        // }
      });
    },
    getSysConfig() {
      localStorage.setItem("system_name", "农村供水信息化技术服务平台");
      showConfig({
        configKey: "system_name"
      }).then(res => {
        if (res.code == 200 && res.data) {
          const name = this.title
          // localStorage.setItem("system_name", res.data.config_value);
          // this.$store.commit("setSystemName", res.data.config_value);
          localStorage.setItem("system_name", name);
          this.$store.commit("setSystemName", name);
        }
      });
      showConfig({
        configKey: "system_logo"
      }).then(res => {
        if (res.code == 200 && res.data) {
          localStorage.setItem("system_logo", res.data.config_value);
          this.$store.commit("setSystemLogo", res.data.config_value);
        }
      });
    },
    sendSms() {
      if(this.phonenumber==''){
        this.$message.error("请输入手机号");
      }else{
      if (this.phonenumber!='' && !/^1[3456789]\d{9}$/.test(this.phonenumber)) {
        this.$message.error("请输入正确的手机号");
        return;
      }else{
          var formData = new FormData();
          formData.append("phone", this.phonenumber);
          sendMessage(formData).then(res => {
            if (res.code == 0) {
              console.log(res);
            } else {
              this.$message.error(res.msg);
            }
          });
          this.time = "59";
          this.isCode = true;
          this.timer = setInterval(() => {
            this.time--;
            if (this.time == 0) {
              this.isCode = false;
              clearInterval(this.timer); //清除定时器
              return;
            }
          }, 1000);
        }
      }
    },
    toPassword() {
      this.isLogin = false;
      this.username= "";
      this.password= "";
    },
    toLogin() {
      this.isLogin = true;
    }
  }
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  background: url(../../assets/img/login/bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.login-title {
  font-size: 48px;
  color: #796f6b;
  text-align: center;
  /*width: 432px;*/
  margin-left: 280px;
}
.login-con {
  width: 457px;
  height: 460px;
  background: #ffffff;
  margin-right: 100px;
  box-sizing: border-box;
  padding: 50px;
  text-align: center;
}
.login-con-title {
  width: 256px;
  height: 72px;
  line-height: 72px;
  font-size: 28px;
  color: #232424;
  border-bottom: 2px solid #2593fc;
  margin: 0 auto;
}
.login-form-forgot {
  text-align: right;
  color: #1890ff;
  cursor: pointer;
}
.login-form-button {
  width: 100%;
  height: 40px;
}
.forgetPassword-div {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d4d9e2;
}
.forgetPassword-input {
  width: 100%;
  height: 36px;
  border: none;
  outline-style: none;
}
.forgetPassword-button {
  width: 140px;
  height: 36px;
  border-radius: 28px;
  background-color: rgba(255, 255, 255, 100);
  color: rgba(89, 157, 238, 100);
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 10px;
  outline-style: none;
  border: 1px solid rgba(89, 157, 238, 100);
}
.disabled {
  width: 140px;
  height: 36px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid #999999;
  font-size: 12px;
  color: #999999;
}
.bottom {
  position: fixed;
  bottom: 20px;
  left: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
.bottom-a {
  color: #ffffff;
  margin: 0px 5px;
}
.bottom-name {
  margin-left: 20px;
}
</style>
