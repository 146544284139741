import http from "@/plugins/http";
export function createConfig(data) {
    return http({
        url: `/system/config`,
        data: data,
        method: "POST"
    });
}

export function showConfig(data) {
    return http({
        url: `/system/config/configKey/${data.configKey}`,
        method: "GET"
    });
}

export function editConfig(data){
    return http({
        url: `/system/config`,
        data: data,
        method: "PUT"
    });
}
